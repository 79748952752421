<template>
<div :class="['AuthLayout', { 'AuthLayout__overlay': overlay }]">
  
  <div class="AuthLayout__wrap">
  
    <HeaderLayout />
    
    <div class="AuthLayout__content">
      <slot name="content" />
    </div>
    
    <FooterLayout
      v-if="footer"
    />
    
  </div>
  
</div>
</template>

<script>
import FooterLayout from "@/components/patient/auth.layout/FooterLayout"
import HeaderLayout from "@/components/patient/auth.layout/HeaderLayout"

export default {
  name: "AuthLayout",
  props: {
    overlay: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    HeaderLayout,
    FooterLayout
  },
}
</script>

<style lang="scss" scoped>
.AuthLayout {
  background-color: $backgroundGray;
  position: relative;
  
  &__wrap {
    min-height: 100vh;
    min-width: $minWidthPatient;
    max-width: $maxWidthPatient;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: white;
    
    @media (min-width: $maxWidthPatient) {
      border-width: 0 1px;
      border-style: solid;
      border-color: #e1e1e2;
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 5%);
    }
  }
  
  &__overlay:after {
    content: "";
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0 / 0.4);
    left: 0;
    top: 0;
    z-index: 1;
  }
  
  &__content {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
}
</style>