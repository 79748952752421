<template>
<div class="HeaderLayout">
  
  <img
    class="HeaderLayout__logo"
    src="@/assets/img/logo_big.svg"
  >
  
  <a
    class="HeaderLayout__support-wrap"
    href="https://functionalgutdiagnostics.com/products-and-services/breath-testing/"
    target="_blank"
    rel="noreferrer"
  >
    <div class="HeaderLayout__support-label">
      FAQ
    </div>
    <div class="HeaderLayout__support">
      <QuestionOutlined
        class="HeaderLayout__support-icon"
      />
    </div>
  </a>

</div>
</template>

<script>
import { QuestionOutlined } from '@ant-design/icons-vue'

export default {
  name: "HeaderLayout",
  components: {
    QuestionOutlined,
  },
}
</script>

<style lang="scss" scoped>
.HeaderLayout {
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  
  &__logo {
    height: 28px;
  }
  
  &__support-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  
  &__support-label {
    color: $polarGreen7;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  
  &__support {
    width: 24px;
    height: 24px;
    background: $polarGreen7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
  }
  
  &__support-icon {
    font-size: 12px;
    color: white;
  }
  
}
</style>